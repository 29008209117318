/* Container for centering profile content */
.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #07070D; /* Dark background for contrast */
    padding: 20px;
}

/* Parent container for flex layout */
.profile-parent {
    display: flex;
    flex-direction: column; 
    align-items: center;
    text-align: center;
    color: white;
}

/* Name styling */
.profile-details-name {
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    font-weight: lighter;
    font-style: italic;

}
.name {
    font-size: 68px;
}

/* Role description styling */
.profile-details-role {
    margin-bottom: 100px;
}

.profile-details-role h2 {
    font-size: 32px;
    font-family: 'Roboto', sans-serif;
}

/* Button styling */
.btn {
    padding: 10px 20px;
    font-size: 14px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    margin: 10px;
    transition: background-color 0.3s, transform 0.3s;
}
.btn-sec:hover {
    background-color: white;
    color: #ff5823;
    border: 2px solid #ff5823;
}
.btn-sec {
    border: 2px solid white;
}

.primary-btn {
    background: linear-gradient(45deg, #ff5823, #ff7823);
    border: 2px solid white;


}

.primary-btn:hover {
    background: white;
    color: #ff5823;

}

/* Social icons styling */
.colz {
    margin-top: 20px;
}

.colz .colz-icon {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.colz .colz-icon a {
    color: white;
    font-size: 24px;
    transition: color 0.3s, transform 0.3s;
}

.colz .colz-icon a:hover {
    color: #ff5823;
    transform: scale(1.2);
}

/* Responsive design adjustments */
@media screen and (max-width: 768px) {
    .profile-details-name {
        font-size: 24px;
    }

    .profile-details-role h2 {
        font-size: 28px;
    }

    .btn {
        padding: 8px 16px;
        font-size: 12px;
    }

    .profile-options {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {

    .profile-container{
        margin-top: 100px;

    }
    .profile-details-name {
        font-size: 20px;
    }

    .profile-details-role h2 {
        font-size: 24px;
    }

    .btn {
        padding: 6px 12px;
        font-size: 10px;
    }

    .profile-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px; /* Add space between buttons */
    }
}
