.home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 670px;
    background: linear-gradient(
        rgba(4, 4, 4, 0.879), 
        rgba(26, 26, 84, 0.85)
    ), url('../../assets/Home/bg.jpg');
    
}