/* Base styling for the header container */
.header-container {
  display: flex;
  justify-content: center;
  height: 149px;
  color: var(--white);
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it is above other content */
}

/* Styling when scrolled */
.header-container.scrolled {
  background-color: rgba(0, 0, 0, 0.9); /* Darker background when scrolled */
  color: var(--light-gray); /* Light text color for contrast */
}

/* Header parent styling */
.header-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 70%;
  color: var(--white);
}

/* Logo styling */
.header-logo img {
  height: 10rem; /* Adjusted for better fit */
  width: 10rem; /* Adjusted for better fit */
}

/* Header options styling */
.header-options {
  display: flex;
  align-items: center;
  transition: transform 0.3s; /* Smooth transition for visibility */
}

.header-option {
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  color: var(--white); /* Default text color */
}

.header-option-seperator {
  margin-right: 30px; /* Adjusted margin */
}

.selected-header-option {
  color: var(--dark-orange);
}

/* Hamburger menu styling */
.header-hamburger {
  display: none;
  color: var(--white);
}

.header-hamburger-bars {
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
}

/* Responsive styling */
@media only screen and (max-width: 970px) {
  .header-container{
    height: 50px;
  }
  .header-logo img {
    height: 4rem; /* Adjusted for better fit */
    width: 4rem; /* Adjusted for better fit */
  }
  .header-hamburger {
    display: block;
  }

  .header-parent {
    width: 100%;
    padding: 0 20px; /* Adjusted padding for responsiveness */
    position: relative;
  }

  .header-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -100%;
    transition: all 0.3s ease-out; /* Adjusted transition for smoothness */
    height: 100vh;
    width: 100%;
    justify-content: center;
    background-color: #1f2235;
    z-index: 1000;
    opacity: 0.9; /* Adjusted opacity for better visibility */
  }

  .header-options.show-hamburger-options {
    left: 0;
    opacity: 1;
  }

  .header-option-seperator {
    margin: 0;
  }

  .selected-header-option {
    color: var(--light-gray);
  }
}
